import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { createContext, useContextSelector } from 'use-context-selector';
import Client, { Cart } from "shopify-buy"
import fetch from "isomorphic-fetch"

const client = Client.buildClient(
  {
    domain               : process.env.GATSBY_SHOPIFY_STORE_URL!,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN!,
  },
  // @ts-ignore
  fetch
)

type TCart = {
  cart: [],
  isOpen: boolean,
  loading: boolean,
  onOpen: () => void,
  onClose: () => void,
  didJustAddToCart: boolean,
  addVariantToCart: ( variantId: string, quantity: string ) => void,
  removeLineItem: ( checkoutID: string | number, lineItemID: string | number ) => void,
  updateLineItem: ( checkoutID: string | number, lineItemID: string | number, quantity: string | number ) => void,
  client: Client.Client,
  checkout?: Cart,
}

const defaultValues: TCart = {
  cart            : [],
  isOpen          : false,
  loading         : false,
  didJustAddToCart: false,
  onOpen          : () => {
  },
  onClose         : () => {
  },
  addVariantToCart: () => {
  },
  removeLineItem  : () => {
  },
  updateLineItem  : () => {
  },
  client,
  checkout        : undefined
}

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`
const isDev = process.env.NODE_ENV === "development"

export const StoreContext = createContext<TCart>( defaultValues )

type StoreProvider = PropsWithChildren<{}>

export const StoreProvider: FC<StoreProvider> = ( { children } ) => {
  const [ checkout, setCheckout ] = useState( defaultValues.checkout )
  const [ loading, setLoading ] = useState( false )
  const [ didJustAddToCart, setDidJustAddToCart ] = useState( false )

  // Setting initial Checkout in localStorage
  const setCheckoutItem = ( checkout: Cart ) => {
    if (isBrowser) {
      localStorage.setItem( localStorageKey, checkout.id.toString() )
    }

    setCheckout( checkout )
  }

  // initialize Cart
  useEffect( () => {

    const initializeCheckout = async () => {
      setLoading( true )
      const existingCheckoutID = isBrowser
                                 ? localStorage.getItem( localStorageKey )
                                 : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem( existingCheckout )
            setLoading( false )
            return
          }
        } catch (e) {
          localStorage.setItem( localStorageKey, 'null' )
          setLoading( false )
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem( newCheckout )
      setLoading( false )
    }

    initializeCheckout().then( () => {
      isDev && console.log( "Checkout Initialized" )
    } )
  }, [] )

  const addVariantToCart = ( variantId: string, quantity: string ) => {
    setLoading( true )
    const checkoutID = checkout?.id

    if (!checkoutID) {
      setLoading( false )
      isDev && console.log( "addVariantToCart: checkoutID dose not exist" )
      return
    }

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt( quantity, 10 ),
      },
    ]

    return client.checkout
      .addLineItems( checkoutID, lineItemsToUpdate )
      .then( ( res ) => {
        setCheckout( res )
        setLoading( false )
        setDidJustAddToCart( true )
        setTimeout( () => setDidJustAddToCart( false ), 3000 )
      } )
  }

  const removeLineItem = ( checkoutID: string | number, lineItemID: string | number ) => {
    setLoading( true )

    return client.checkout
      .removeLineItems( checkoutID, [ lineItemID.toString() ] )
      .then( ( res ) => {
        setCheckout( res )
        setLoading( false )
      } )
  }

  const updateLineItem = ( checkoutID: string | number, lineItemID: string | number, quantity: string | number ) => {
    setLoading( true )

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt( quantity.toString(), 10 ) },
    ]

    return client.checkout
      .updateLineItems( checkoutID, lineItemsToUpdate )
      .then( ( res ) => {
        setCheckout( res )
        setLoading( false )
      } )
  }


  return (
    <StoreContext.Provider
      value={ {
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        client,
      } }
    >
      { children }
    </StoreContext.Provider>
  )
}

export const useAddVariantToCart = () => useContextSelector( StoreContext, s => ({
    addVariantToCart: s.addVariantToCart,
    loading         : s.loading
  })
)

export const useCheckout = () => useContextSelector( StoreContext, s => ({
    checkout: s.checkout,
    loading : s.loading
  })
)

export const useLineItemContext = () => useContextSelector( StoreContext, s => ({
    removeLineItem: s.removeLineItem,
    updateLineItem: s.updateLineItem,
    checkout      : s.checkout,
    loading       : s.loading
  })
)

export const useRemoveLineItem = () => useContextSelector( StoreContext, s => s.removeLineItem )
export const useUpdateLineItem = () => useContextSelector( StoreContext, s => s.updateLineItem )
export const useCartLoading = () => useContextSelector( StoreContext, s => s.loading )

export const useDidJustAddToCart = () => useContextSelector( StoreContext, s => s.didJustAddToCart )
export const useClient = () => useContextSelector( StoreContext, s => s.client )
