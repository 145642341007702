import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { createContext, useContextSelector } from 'use-context-selector';

const isBrowser = typeof window !== `undefined`
export type TColorTheme = 'dark' | 'light' | null

type TTheme = {
  theme: TColorTheme
  setThemeAndLocal: ( theme: TColorTheme ) => void
}

export const ThemeContext = createContext<TTheme>( {
  theme: null,
  setThemeAndLocal() {
  }
} )

type ThemeProvider = PropsWithChildren<{}>

export const ThemeProvider: FC<ThemeProvider> = ( { children } ) => {
  const [ theme, setTheme ] = useState<TColorTheme>( null )

  function setThemeAndLocal( t: TColorTheme ) {
    setTheme( t )
    localStorage.setItem( 'color-theme', t ?? "null" )
    document.body.setAttribute( 'data-theme', t ?? "" )
  }


  function getLocalThem() {
    if (isBrowser) {
      const mediaQuery = window?.matchMedia( "(prefers-color-scheme: dark)" )
      const savedTheme = localStorage.getItem( "color-theme" ) as TColorTheme | "null" | null
      if (savedTheme && savedTheme !== "null") {
        setThemeAndLocal( savedTheme )
      } else {
        const isDark = mediaQuery.matches ? 'dark' : 'light';
        setThemeAndLocal( isDark )
      }
    }
  }

  useEffect( getLocalThem, [] )

  useEffect( () => {
    const mediaQuery = window?.matchMedia( "(prefers-color-scheme: dark)" )

    function handleChangeTheme( e: MediaQueryListEvent ) {
      const isDark = e.matches ? 'dark' : 'light';
      setThemeAndLocal( isDark )
    }

    mediaQuery.addEventListener( 'change', handleChangeTheme )

    return () => mediaQuery.removeEventListener( 'change', handleChangeTheme )
  }, [ theme ] )
  return (
    <ThemeContext.Provider value={ {
      theme,
      setThemeAndLocal
    } }>
      { children }
    </ThemeContext.Provider>
  )
}

export const useGetTheme = () => useContextSelector( ThemeContext, ( s ) => s )
