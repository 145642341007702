import React from "react";
import {GatsbyBrowser, Script} from "gatsby";

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({element, props}) => {
    return (
        <>
            {process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY &&
                <Script
                    key={"recaptcha-script"}
                    src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}`}
                />
            }

            {process.env.NODE_ENV === "production"
                && process.env.GATSBY_GOOGLE_ANALYTICS_ID
                && <>
                    <Script
                        id={"google-analytics-script"}
                        key={"google-analytics-script"}
                        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`}
                    />
                    <Script
                        key={"google-analytics-config"}
                        id={"google-analytics-config"}
                        forward={[`gtag`]}
                    >
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS_ID}');
                    `}
                    </Script>
                </>
            }
            {process.env.NODE_ENV === "production"
                && process.env.GATSBY_FACEBOOK_PIXEL_ID
                && <>
                    <Script
                        id={"facebook-pixel-script"}
                        key={"facebook-pixel-script"}
                        forward={[`fbq`]}
                    >
                        {`
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '${process.env.GATSBY_FACEBOOK_PIXEL_ID}');
                            fbq('track', 'PageView');
                        `}
                    </Script>
                    <noscript>
                        <img alt="" height="1" width="1" style={{display: "none"}}
                             src={`https://www.facebook.com/tr?id=${process.env.GATSBY_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
                        />
                    </noscript>
                </>
            }
{/*
            {process.env.NODE_ENV === "production" &&
                <Script defer data-domain="soapupproject.com" src="https://plausible.io/js/script.js"/>
            }*/}
            <div key={props.location.pathname}>
                {element}
            </div>
        </>
    )
}
export default wrapPageElement
