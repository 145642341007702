import { GatsbyBrowser } from "gatsby";
import { LayoutContextProvider, StoreProvider, ThemeProvider } from "@store";
import React from "react";

const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ( { element } ) => (
  <ThemeProvider>
    <LayoutContextProvider>
      <StoreProvider>
        { element }
      </StoreProvider>
    </LayoutContextProvider>
  </ThemeProvider>
)

export default wrapRootElement
